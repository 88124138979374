const SEO = [
    {
        page: "home",
        description:
            "Hi! I'm Anna, professional hairdresser and colorist.",
        keywords: [
            "haircut",
            "hairdresser",
            "professional hairdresser",
            "Anna Veretynska",
            "Beauty By Anna",
            "colorist",
            "hair treatment",
            "scalp treatment",
            "women haircut",
            "men haircut",
            "balayage",
            "NOVA hairdresser",
            "Virginia hairdresser",
            "Tysons hairdresser",
            "keratin treatment"
        ],
    },
    {
        page: "about",
        description:
            "I specialize on men, women and kids haircut.",
        keywords: [
            "haircut",
            "hairdresser",
            "professional hairdresser",
            "Anna Veretynska",
            "Beauty By Anna",
            "colorist",
            "hair treatment",
            "scalp treatment",
            "women haircut",
            "men haircut",
            "balayage",
            "NOVA hairdresser",
            "Virginia hairdresser",
            "keratin treatment"
        ],
    },
    {
        page: "contact",
        description:
            "Follow me on instagram: https://www.instagram.com/annaveret_hair",
        keywords: [
            "haircut",
            "hairdresser",
            "professional hairdresser",
            "Anna Veretynska",
            "Beauty By Anna",
            "colorist",
            "hair treatment",
            "scalp treatment",
            "women haircut",
            "men haircut",
            "balayage",
            "NOVA hairdresser",
            "Virginia hairdresser",
            "keratin treatment"
        ],
    },
];

export default SEO;
