const INFO = {
	main: {
		title: "Beauty by Anna",
		name: "Anna",
		email: "anna@gmail.com",
		logo: "../logo.png",
	},

	socials: {
		instagram: "https://www.instagram.com/annaveret_hair",
	},

	homepage: {
		title: "Hi! I'm Anna, professional colorist, hairdresser and hair treatment specialist.",
		description:
			"I specialize in creating stunning looks for men, women and kids. Whether you're looking for a stylish haircut, intricate coloring, or effective hair and scalp treatments, you're in the right place.",
	},

	services: [
		{
			title: "Women Haircuts",
			description: "Customized styles tailored to your preferences and face shape. Trendy and classic cuts to suit every occasion.",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/javascript/javascript.png",
			linkText: "View",
			link: "https://www.instagram.com/p/Cz6VaslutcQ/",
			photo: "./photo1.jpeg"
		},
		{
			title: "Men Haircuts",
			description: "I specialize in providing professional haircuts for men, delivering precision and style tailored to each client's needs.",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/javascript/javascript.png",
			linkText: "View",
			link: "https://www.instagram.com/reel/CypbepKtkUY/?igsh=M3d4M3FscTRyMjJ6",
			photo: "./photo2.jpeg"
		},
		{
			title: "Kids Haircuts",
			description: "I offer expert haircuts for kids, ensuring a fun and comfortable experience while delivering a stylish and age-appropriate look.",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/javascript/javascript.png",
			linkText: "View",
			link: "https://www.instagram.com/s/aGlnaGxpZ2h0OjE3ODg0Nzg3NTMwNzM0OTQ3?story_media_id=2490011150026837997&igsh=MWpiMzhpMDd6NGNzaw==",
			photo: "./photo3.jpeg"
		},
		{
			title: "Advanced Coloring",
			description: "Expert coloring techniques for vibrant, lasting results. From natural shades to bold hues, I handle coloring of any complexity.",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/python/python.png",
			linkText: "View",
			link: "https://www.instagram.com/p/Cy1ROcLxvJ4/",
			photo: "./photo4.jpeg"
		},
		{
			title: "Hair and Scalp Treatments",
			description:
				"Comprehensive solutions for healthier hair and scalp. Personalized treatments to address specific concerns.",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/html/html.png",
			linkText: "View",
			link: "https://www.instagram.com/stories/highlights/17858838716479882/",
			photo: "./photo5.jpeg"
		},
		{
			title: "Master Class",
			description:
				"I offer expert haircuts for kids and adults, and also provide master classes to share advanced techniques and industry insights.",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/html/html.png",
			linkText: "View",
			link: "https://www.instagram.com/reel/CqdhVIhsGQv/?igsh=NGlxaHQ0bXVncDV5",
			photo: "./photo6.jpeg"
		}
	],
};

export default INFO;
