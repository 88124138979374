import React from "react";
import {faMapLocation} from "@fortawesome/free-solid-svg-icons";
import Card from "../common/card";
import GoogleMapReact from 'google-map-react';
import "./styles/works.css";

const AnyReactComponent = ({text}) => <div><h1>{text}</h1></div>;
const API_KEY = 'AIzaSyCnElpA6dqMD5AufBNKc-K30sb6ArPaeyc';


const defaultProps = {
    center: {
        lat: 38.924630685704344,
        lng: -77.23363478522937
    },
    zoom: 16
};

const Works = () => {
    return (
        <div className="works">
            <Card
                icon={faMapLocation}
                title="Location: 1636 Boro Pl, Tysons, VA 22102"
                body={<div style={{height: '50vh', width: '100%'}}>
                    <GoogleMapReact
                        bootstrapURLKeys={{key: API_KEY}}
                        defaultCenter={defaultProps.center}
                        defaultZoom={defaultProps.zoom}>
                        <AnyReactComponent
                            lat={38.92455456491411}
                            lng={-77.2336949975125}
                        />
                    </GoogleMapReact>
                </div>}
            />

        </div>
    );
};

export default Works;
